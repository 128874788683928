.report-nav-item {
  transition: all 0.2s ease;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  &:hover {
    transform: scale(1.01);
    font-weight: bold;
  }
}

.report-sidebar-title {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.report-nav-active-item {
  transform: scale(1.01);
  font-weight: bold;
}

#dropdown-report-print-button {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.btn-no-default-style {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
