.custom-field-wrapper {
  .custom-field-label-style {
    display: flex;
    align-items: center !important;

    .form-label {
      margin: 0px !important;
      font-size: 12px !important;
    }
  }

  .creatable-select {
    .css-13cymwt-control {
      height: auto !important;
    }

    .css-t3ipsp-control {
      height: auto !important;
    }

    .css-wsp0cs-MultiValueGeneric {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
}

.custom-filed-simplebar-style {
  height: 55vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.custom-field-wrapper {
  .custom-field-header {
    font-size: 20px;
    color: black;
    padding: 17px;
  }

  .custom-field-main {
    width: 700px;

    .nav-item {
      margin-right: 1.4rem;

      button {
        width: 100px;
      }

      .nav-link {
        width: 125px;
        padding-bottom: 4px;
        color: black !important;
        font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
      }

      .nav-link.active {
        border-width: 20px;
        color: #01875f;
        border-bottom: 3px solid rgb(42, 141, 0);
        background-color: rgb(255, 255, 255) !important;
        // color: black !important;
        font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
      }

    }

  }

  .custom-field-second {
    margin-top: 7px;
    margin-left: 5px;

    ul {
      width: 100%;

    }

    li {
      width: 150px;
      flex-basis: content !important;
      flex-grow: 0 !important;
      text-align: center;
    }

    .nav-item {
      margin-right: 20px !important;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #626262;
        border-radius: 30px !important;
        border: 1px solid #72727252;
        padding: 4px;
        padding-left: 13px;
        padding-right: 13px;

      }
    }

    .nav-link.active {
      background-color: #E6F3EF !important;
      color: #176F56 !important;

    }
  }
}