.dashboard_shake {
    animation: horizontal-shaking 0.7s infinite;
}

@keyframes horizontal-shaking {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(0.3deg);
    }

    50% {
        transform: rotate(-0.3deg);
    }

    75% {
        transform: rotate(0.3deg);
    }

    100% {
        transform: rotate(0);
    }
}

.react-grid-item.react-grid-placeholder {
    background: rgb(36, 100, 240) !important;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    display: grid !important;
    grid-template-columns: auto auto auto !important;
    gap: 2px !important;

}

.react-grid-item-dragging {
    cursor: grab;
}