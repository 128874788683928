.meili-search-modal {
  .highlight {
    background-color: #ffdb00;
    font-weight: bold;
    padding: 2px 0px;
  }

  .search-result-modal-height {
    max-height: 67vh !important;
  }

  .modal-content {
    background-color: transparent !important;
  }
  .top-card {
    margin-bottom: 6px !important;
    .card-header {
      padding: 1.5rem !important;
      padding-bottom: 2px !important;
      .search-input {
        width: 100% !important;
        padding-left: 30px !important;
        padding-right: 10px !important;
      }
      .search-box-icon {
        position: absolute;
        top: 34px !important;
        left: 32px !important;
      }
    }
    .modal-close-button {
      cursor: pointer;
      text-transform: capitalize;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px;
      width: fit-content !important;
      border: 1px solid whitesmoke;
      margin-right: 5px;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
      transition: transform 0.3s;
    }
    .modal-close-button:hover {
      transform: scale(1.05);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
      background-color: rgb(236, 250, 249) !important;
      border: 1px solid rgb(190, 250, 245) !important;
    }
  }
  .bottom-card {
    .module-name-wrapper {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap;
      .module-names {
        cursor: pointer;
        text-transform: capitalize;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        width: fit-content !important;
        border: 1px solid whitesmoke;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        transition: transform 0.3s;
      }
      .module-names:hover {
        transform: scale(1.06);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
        background-color: rgb(236, 250, 249) !important;
        border: 1px solid rgb(190, 250, 245) !important;
      }
      .module-names.active {
        background-color: rgb(236, 250, 249) !important;
        border: 1px solid rgb(190, 250, 245) !important;
      }
    }
    .search-result-body {
      h2 {
        margin: 5px !important;
        text-transform: uppercase;
        font-size: 14px !important;
        color: black;
        font-weight: 600 !important;
      }
      .content-size {
        padding: 2px 10px;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: black;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
      }
      .search-result-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;
        cursor: pointer;
      }
      .search-result-row:hover {
        background-color: rgb(236, 250, 249);
        border: 1px solid rgb(190, 250, 245);
        transform: scale(1.01);
      }
      .eye-button {
        visibility: hidden;
        transition: visibility 0s ease-in-out;
      }
      .search-result-row:hover .eye-button {
        visibility: visible;
      }
    }
    .modal-footer {
      .advance-search-button {
        cursor: pointer;
        text-transform: capitalize;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        width: fit-content !important;
        border: 1px solid whitesmoke;
        margin-right: 5px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        transition: transform 0.3s;
      }
      .advance-search-button:hover {
        transform: scale(1.05);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
        background-color: rgb(236, 250, 249) !important;
        border: 1px solid rgb(190, 250, 245) !important;
      }
    }
  }
}

.offcanvas-custom-style {
  z-index: 1055 !important;
}

.custom-offcanvas-header-tabs {
  flex-direction: row !important;
  flex-wrap: wrap !important;
}
