.search-data-menu {
    /* Styles for the search data menu */

    .search-data-dropdown {
        /* Styles for the dropdown within the search data menu */
        transition: all ease-in-out .2s; /* Add a smooth transition effect for all properties over 0.3 seconds with ease timing function */
    }

    .search-data-dropdown:hover {
        /* Styles applied when the dropdown is hovered */
        scale: 1.111;
        background-color: #ffffff !important;

    
    }
    /* CSS */

  
}
