@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.download-center-button {
  position: relative;
}

.download-center-button-notify {
  &::before {
    position: absolute;
    content: '';
    right: 0.125rem;
    top: -0.2rem;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    border: 2px solid #edf2f9;
    background-color: #e63757;
    transition: all 0.5s ease;
    animation: blink-animation 1s;
    -webkit-animation: blink-animation 1s;
    animation-iteration-count: 7;
  }
}

.download-item {
  .title {
    font-size: 0.9rem;
  }

  .file-name {
    font-size: 0.75rem;
  }

  p,
  small {
    word-break: break-all;
  }

  .action {
    button {
      padding: 2px 7px;
      span {
        font-size: 12px;
        margin-left: 3px;
      }
    }
  }
}

.download-center-quick-notification-screen {
  width: 100vw;
  max-width: 400px;
  height: auto;
  max-height: 500px;
  overflow: hidden;
  right: 3px;
  padding-top: 0;
  background-color: var(--falcon-dropdown-bg);
  border: 1px solid var(--falcon-dropdown-border-color);
  box-shadow: var(--falcon-dropdown-box-shadow);
  box-shadow: var(--falcon-dropdown-box-shadow);

  .header-section {
    padding: 10px;
    p {
      font-size: 15px;
      color: var(--falcon-1000);
    }
    button {
      outline: none;
      box-shadow: none;
    }
  }

  .downloads-list-section {
    height: 100%;
    overflow-y: auto;
    ul {
      li {
        &:last-child {
          .download-item {
            border-bottom: none !important;
          }
        }
      }
    }
  }

  .footer-section {
    padding: 10px;
    button {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
