$md-screen: 768px;
$bg-color: #2e2e2e;
$bg-transparent: #2e2e2ebd;
.installation-screen-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $bg-color;
  padding: 15px;
  background-image: url(../images/intallation-screen-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bg-transparent;
    top: 0;
    left: 0;
  }

  .brand-logo {
    position: relative;
    z-index: 1;
  }

  .form-container {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    transition: all ease 0.5s;
    position: relative;
    z-index: 1;
  }

  .form-container-md {
    @media (min-width: $md-screen) {
      max-width: 485px;
    }
  }

  .success-container {
    max-width: 360px;
  }

  .tab-area {
    button {
      span {
        @media only screen and (max-width: 380px) {
          display: none;
        }
      }
    }
  }
}
