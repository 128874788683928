.fixed-asset-detail-wrapper {
  padding: 20px;
  background-color: rgb(241, 248, 254);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  .image-slider {
    height: 15rem !important;
    width: 23.5rem !important;
    .mySwiper {
      width: 100%;
      height: 100%;
    }

    .mySwiper .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mySwiper .swiper-slide img {
      width: 100% !important;
      height: 15rem !important;
      object-fit: cover !important;
    }
  }
}

.fixed-asset-view-page {
  .accordion {
    padding: 0px !important;
  }
  .accordion-item {
    border-radius: 0px !important;
  }
  .accordion-button {
    background-color: rgb(241, 248, 254);
    border-radius: 0px !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 8px 20px 8px 3px;
    font-size: 13px;
    color: black;
    font-weight: 700;
  }
  .accordion-button:not(.collapsed) {
    background-color: rgb(241, 248, 254);
    border-radius: 0px !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 8px 20px 8px 10px;
    font-size: 13px;
    color: black;
  }
}
