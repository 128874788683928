.approval-stages {
  .stage-trans-type {
    .single {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

      :hover {
        background-color: rgb(225, 225, 225) !important;
        color: #000000;
      }
    }

    .active {
      background-color: rgb(115, 115, 115) !important;
      color: #ffffff !important;
    }
  }

  .create_stage {
    .create_stage_button {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      outline: none !important;
      font-size: 13px;
    }

    .main-div {
      height: 100%;
      overflow-x: scroll;
    }

    .line-vertical {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .line {
        height: 50px !important;
        width: 1px !important;
        border: 1px solid #bdbdbd;
      }

      .down-arrow {
        bottom: -6px;
        left: 48.8%;
      }
    }

    .single-stage-card {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 1000px;
      height: 115px;
      display: flex !important;

      .icon-div {
        width: 100px;
        height: 115px;
        background-color: #d1d1d1;

        .stage-number {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000000;
          width: 30px;
          height: 30px;
          font-size: 13px;
          border-radius: 50%;
          background-color: #ffffff;
          font-weight: 800;
        }
      }

      .card-header {
        font-size: 14px;
      }

      .stage-heading {
        font-size: 14px;
        color: black;
      }

      .dropdown-toggle::after {
        display: none !important;
      }

      .dropdown {
        .dropdown-toggle {
          box-shadow: none !important;
        }
      }
    }

    .number-showing {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: antiquewhite;
    }

    .dot-line {
      width: 50%;
      height: 4px;
      border-bottom: 2px dotted;
      border-width: 1px;
    }
  }
}

.approval-tab {
  button {
    font-size: 12px !important;
  }
}

.approval-request-table-button {
  font-size: 10px !important;
  background-color: #4bb54b !important;
  border: none !important;
  width: 65px !important;
  padding: 3px !important;
}

.reject-request-table-button {
  font-size: 10px !important;
  background-color: #cb062a !important;
  border: none !important;
  width: 65px !important;
  padding: 3px !important;
}

.approval-request-info-button {
  font-size: 14px !important;
  background-color: #4bb54b !important;
  border: none !important;
  width: 100px !important;
  padding: 3px !important;
}

.reject-request-info-button {
  font-size: 14px !important;
  background-color: #cb062a !important;
  border: none !important;
  width: 100px !important;
  padding: 3px !important;
}

.approval-history {
  .approved-color {
    width: 10px;
    height: 10px;
    background-color: #9beb9b;
  }

  .rejected-color {
    width: 10px;
    height: 10px;
    background-color: #ffe5ef;
  }

  .single-card-approved {
    height: 100px;
    background-color: #cbffcb;
    border-radius: 20px;

    .h1 {
      color: #000000;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }

  .single-card-end {
    height: 100px;
    background-color: #ffffff;
    border-radius: 20px;

    .h1 {
      color: #000000;
      font-size: 13px !important;
      font-weight: 600 !important;
    }
  }

  .single-card-reject {
    height: 100px;
    background-color: #ffc7c7;
    border-radius: 20px;

    .h1 {
      font-size: 13px !important;
      font-weight: 600 !important;
    }
  }

  .single-start-card {
    height: 100px;
    background-color: white;
    border-radius: 0px;
    border: 2px solid black;

    .h1 {
      font-size: 13px !important;
      font-weight: 600 !important;
    }
  }

  .line-approval {
    height: 1px;
    width: 100%;
    background-color: #848484;
  }

  .create-user {
    position: absolute;
    bottom: 2px;
    left: 12px;
    font-size: 11px;
  }

  .created-time {
    position: absolute;
    bottom: 2px;
    right: 12px;
    font-size: 11px;
  }
}

.start-and-end-flow {
  height: 100% !important;
  width: 100% !important;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    margin: 0px !important;
    font-size: 16px !important;
  }
}
