.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__predefined-ranges {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    .list-group {
        div {
            &:hover {
                background-color: #c7d2f3;
            }
        }
    }

    .date-range-month {
        width: 100px;
        height: 50px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #c7d2f3;
        }
    }

    .active-month {
        background-color: #4c6099;
        color: #ffff;
    }

    .react-datepicker__ranges {
        max-height: 17rem;
        width: 15.5rem;
        overflow: hidden;
        overflow-y: scroll;
        color: black;
        background-color: white !important;
        border-right: 1px solid #dedede !important;

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #eaf1fb !important;
            border-radius: 10px !important;
        }

        &::-webkit-scrollbar {
            width:4.9px !important;
            height: 10px !important;
            display: inline;
            unicode-bidi: bidi-override;
            margin-left: 20px;
            position: fixed;
            bottom: 0;
            right: 0;
            z-index: 1;
            box-shadow: inset 0 0 5px #eaf1fb !important;
        }

        &::-webkit-scrollbar-thumb {
            background: #dddddd !important;
            border-radius: 10px !important;
            margin-left: 20px !important;
            
        }
    }

    .react-datepicker__navigation--previous {
        left: calc(7rem + 150px) !important;
    }
}