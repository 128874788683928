.rental-status-dropdown {
    button {
        outline: none;
    }

    font-size: 10px;

    .dropdown-toggle:empty:after {
        color: #ffff !important;
        font-size: 18px;

    }
}

.spinner-for-rental-contract {
    height: 14px !important;
    width: 14px !important;
}

.rental-contract-status-badge {
    height: 26px;
}

.rental-contract-return-button {
    font-size: 10px !important;
    height: 29px;
    letter-spacing: 0.6px;
}