.system-settings-table{
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 15.8px !important;
    color: black;
    .settings-edit{
       visibility: hidden;
    }
    .border-bottom-zero{
        border-bottom-width: 0px !important;
        padding: 0.50rem !important;
        text-transform: capitalize;
    }
    tr{
        :hover{
       
            .settings-edit{
           visibility: visible;
            }
        }
    }
    tr:hover .settings-edit {
        visibility: visible;
      }
    .sub-heading{
        width: 80px;
    }
    .second-column{
        width: 80px;
    }
    tbody {
        .no-box-shadow {
            &:hover > * {
                background-color: rgb(255, 255, 255) !important; // Set the desired background color for hover
                --falcon-table-accent-bg:'none '!important
            }
        }
    

}}