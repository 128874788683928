.user-details-wrapper {
  .offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none !important;
  }
  .user-details-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .image-div {
      width: 12rem;
      height: 12rem;
      overflow: hidden;
      border-radius: 50%;
      .image-section {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .user-name {
      font-size: 20px !important;
      margin-top: 8px;
      text-transform: uppercase;
      margin-bottom: 0px !important;
    }
    .general-information {
      padding: 15px;
    }
  }
  .nav-tabs .nav-link {
    font-size: 11px !important;
    padding: 5px;
  }
  .nav-tabs .nav-link.active {
    font-size: 11px !important;
    padding: 5px;
  }
}
