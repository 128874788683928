@keyframes animateTableContentView {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.simple-table-animation {
  tbody {
    tr {
      transform-origin: top left;
      animation-name: animateTableContentView;
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
    }
  }
}

.kit-items-modal-cover {
  padding-left: 30px;
  padding-right: 30px;
  display: none;
  .kit-items-modal-view {
    width: 100%;
    max-width: 1500px;
  }
}
