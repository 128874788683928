.estimation-entries-section-cover {
  background-color: #edf2f9;
  padding: 15px;
  border: 1px solid #cbd8eb;

  .form-control {
    background-color: #fff !important;
    border-color: #dde1e7 !important;
  }

  .item-select-autocomplete__control {
    border-color: #dde1e7 !important;
  }

  .title {
    color: #000;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0;
  }

  .title-items {
    th {
      background-color: #213e56;
      color: #fff;
      font-weight: 700;
      font-size: 0.75rem;
      padding: 5px 10px;
    }
  }

  .form-items {
    .form-item {
      padding: 10px 15px;
    }
  }

  .result-items {
    .result-item {
      padding: 10px 15px;
      color: #1a1a1a;
      font-size: 0.75rem;
    }

    .action-buttons {
      button {
        box-shadow: none;
        width: 20px;
        height: 20px;
        border-radius: 50% !important;
      }
    }
  }

  .total-items {
    .total-item {
      color: #000;
      font-weight: 700;
    }
  }

  $borderColor: #213e5633;

  table {
    margin-bottom: 0;
    tr {
      th,
      td {
        border: 1px solid $borderColor;
        border-right: none;
        &:last-child {
          border-right: 1px solid $borderColor;
        }
        &:nth-child(7) {
          border-right: 1px solid $borderColor;
          border-left: none;
        }
      }
    }

    .head-tr {
      th {
        border: none !important;
      }
    }
  }

  .item-select-cover {
    min-width: 350px;
  }
}

.estimation-entries-section-line-item-cover {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #cbd8eb;

  .title {
    color: #000;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0;
  }

  .title-items {
    th {
      background-color: #213e56;
      color: #fff;
      font-weight: 700;
      font-size: 0.75rem;
      padding: 5px 10px;
    }
  }

  .result-items {
    .result-item {
      color: #1a1a1a;
      font-size: 0.75rem;
    }

    .action-buttons {
      button {
        box-shadow: none;
        width: 20px;
        height: 20px;
        border-radius: 50% !important;
      }
    }
  }

  .total-items {
    .total-item {
      color: #000;
      font-weight: 700;
    }
  }

  $borderColor: #213e5633;

  table {
    margin-bottom: 0;
    tbody {
      border: 1px solid transparent;
    }
    tr {
      th,
      td {
        border: 1px solid $borderColor;
        border-right: none;
        &:last-child {
          border-right: 1px solid $borderColor;
        }
        &:nth-child(7) {
          border-right: 1px solid $borderColor;
          border-left: none;
        }
      }
    }

    .head-tr {
      th {
        border: none !important;
        box-shadow: none !important;
        background-color: #fff !important;
      }
    }
  }

  .item-select-cover {
    min-width: 350px;
  }
}
