.layout-vat-reports{
    min-width: 1300px;
  
    min-height: 880px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
 .scaled-class{
    transform: scale(0.9);
    .table{

    
        .table-heading{
            background-color: #E7E7E7
        
        }
    }
 }
  
}