.form-layer-container {
  position: relative;
  .form-layer-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ) !important;
    opacity: 10;
    transition: 0.5s ease-in;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}
