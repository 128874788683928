.settings-item{
    // transition: background-color .5s ease;
    &:hover{
        background-color: #2c7be5;
        .icon, .name{
            color: #fff;
        }
    }
    // background-color: #000;
    .icon{
        color: #ccc;
    }
    .name{
        color: #000;
        font-weight: 500;
    }
}


.active-settings-item{
    background-color: #2c7be5 !important;
    .icon, .name{
        color: #fff !important;
    }
}