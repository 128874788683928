.reportTableCover {
  .table > :not(caption) > * > * {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
  width: 100% !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  min-height: 70vh !important;
  // height: 70vh !important;

  @media only screen and (min-width: 700px) {
    width: auto;
    min-width: 750px;
  }
}

.ledgerreportTableCover {
  .table > :not(caption) > * > * {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
  width: 90% !important;
  @media only screen and (min-width: 700px) {
    width: auto;
    min-width: 1000px;
  }
}
.ledgerreportOffcanvasTableCover {
  .table > :not(caption) > * > * {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
  width: 100% !important;
  @media only screen and (min-width: 700px) {
    width: auto;
    min-width: 1000px;
  }
}

.ledger-report-table-heading {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.ledger-report-contents {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  color: black !important;
}

.trial-balance-style {
  text-align: end !important;
}
