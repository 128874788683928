.tableFixHead {
  overflow-y: scroll;
  height: 770px;
  td {
    padding: 4px 8px;
    border: 1px solid rgb(150, 148, 148) !important;
    text-align: center !important;
  }
  tr:hover {
    background-color: #ebebeb !important;
  }
  th {
    background: black;
    text-align: center !important;
    text-transform: uppercase;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
}
.tableFixHead thead th {
  position: sticky;
  top: 0px;
  color: white;
}

.tableFixHeadforReport {
  overflow-y: scroll;
  height: 380px;
  font-size: 10px;
  td {
    padding: 4px 8px;
    border: 1px solid rgb(150, 148, 148) !important;
    text-align: center !important;
  }
  tr:hover {
    background-color: white !important;
  }
  th {
    background: black;
    text-align: center !important;
    text-transform: uppercase;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
}
.tableFixHeadforReport thead th {
  position: sticky;
  top: -5px;
  color: white;
}
