.vtl {
  position: relative;
  padding: 10px 10px 10px 50px;
}
.vtl,
.vtl * {
  box-sizing: border-box;
}

.vtl::before {
  content: '';
  width: 5px;
  background-color: #337ed4;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
}

.rtl {
  position: relative;
  padding: 10px 10px 10px 50px;
}
.rtl,
.rtl * {
  box-sizing: border-box;
}

.rtl::before {
  content: '';
  width: 5px;
  background-color: #337ed4;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 533px;
}

div.event {
  padding: 15px 15px;
  background-color: #edf2f9;
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;
  width: 50%;
}

p.date {
  font-size: 1.1em;
  font-weight: 700;
  color: #4192e3;
}
p.txt {
  margin: 10px 0 0 0;
  color: #222;
}

div.event::before {
  content: '';
  border: 10px solid transparent;
  border-right-color: #edf2f9;
  border-left: 0;

  position: absolute;
  top: 20%;
  left: -10px;
}

div.event::after {
  content: '';
  background: #fff;
  border: 4px solid #3f91e3;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  position: absolute;
  top: 20%;
  left: -44px;
}
.rtl .event::after {
  left: -93px !important;
}
* {
  box-sizing: border-box;
}

.widget-wrap {
  min-width: 250px !important;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.4);
}

#grid {
  width: 100%;
  height: 100px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 512 512" width="100" xmlns="http://www.w3.org/2000/svg"><path d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" /></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

#code-boxx {
  font-weight: 600;
  margin-top: 50px;
}
#code-boxx a {
  display: inline-block;
  padding: 5px;
  text-decoration: none;
  background: #b90a0a;
  color: #fff;
}
#code-boxx,
h1 {
  text-align: center;
}
