.settings-item:hover {
  background-color: #2c7be5;
}
.settings-item:hover .icon, .settings-item:hover .name {
  color: #fff;
}
.settings-item .icon {
  color: #ccc;
}
.settings-item .name {
  color: #000;
  font-weight: 500;
}

.active-settings-item {
  background-color: #2c7be5 !important;
}
.active-settings-item .icon, .active-settings-item .name {
  color: #fff !important;
}/*# sourceMappingURL=SettingsItem.css.map */