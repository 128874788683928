.dynamic-proposal-cover {
  width: 100%;
  position: relative;
  .page-width {
    max-width: 1123px;
    min-height: 100%;
  }

  .config-width {
    max-width: 350px;

    .config-section {
      position: sticky;
      top: 0;
    }

    input {
      &::placeholder {
        color: #b8b1b1 !important;
      }
    }

    .padding-input {
      width: calc(100% / 4.5);
    }

    .page-add-button {
      border: 1px dashed #ccc;
      transition: all 0.5s ease;
      &:hover {
        background-color: #f3f2f2;
      }
    }

    .dm-pr-pdf-page-item {
      &:hover {
        cursor: move;
        cursor: grab;

        .svg-bars {
          opacity: 0.8;
        }
      }
      &:active {
        cursor: grabbing;
      }

      svg {
        transition: all 0.5s ease;
      }

      .svg-bars {
        opacity: 0.4;
      }

      .f-name {
        font-size: 0.73rem;
      }

      button {
        &:hover {
          svg {
            color: red;
          }
        }
      }
    }

    .file-name-section {
      .file-rename-btn {
        transition: all 0.5s ease-in;
        &:hover {
          color: #00d27a;
        }
      }
    }

    .dm-pr-dynamic-btn-item {
      transition: all 0.5s ease;
      font-size: 0.75rem;
      &:hover {
        transform: scale(1.01);
      }
    }

    .dm-pr-pdf-padding-target-btn {
      width: 20px;
      height: 20px;
      overflow: hidden;
      position: relative;
      transition: all 0.5s ease;
      svg {
        transition: all 0.5s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        transform: scale(1.1);
        background-color: #e6e7ec !important;
      }
    }
  }

  .form-layer-wrapper {
    position: fixed;
  }
  .proposal-layout-item-cover {
    .proposal-layout-item {
      position: relative;
      height: 100%;
      width: 100%;
      max-height: 350px;
      overflow: hidden;
      overflow-y: auto;
      cursor: pointer;
      transition: all 0.5s ease;

      .content-area {
        position: relative;
        transition: all 60s ease;
        img {
          max-width: 100%;
        }
      }

      &:hover {
        transform: scale(1.02);
        .content-area {
          transform: translateY(-50%);
        }
      }
    }

    .actions {
      button {
        &:hover {
          color: #fff;
          &:first-child {
            background-color: #2688ff;
            border-color: #2688ff;
          }
          &:last-child {
            background-color: #dc3545;
            border-color: #dc3545;
          }
        }
      }
    }
  }

  // quill updates
  .ql-container {
    min-height: 250px;
  }
  .ql-editor {
    padding: 0;
    min-height: 250px;
  }
  .ql-toolbar {
    position: sticky;
    top: -1rem;
    left: 0;
    z-index: 1;
    background-color: #fff;
  }

  .ql-container {
    p {
      color: #000;
    }
  }
}

.proposal-page-selection-modal {
  z-index: 1056;
  background-color: #fffdfdd1;

  .modal-header {
    .title {
      font-size: 1rem;
    }

    .file-upload-btn {
      border: 1px dashed #ccc;
      transition: all 0.5s ease;
      &:hover {
        background-color: #fff;
      }
    }
  }

  .modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;

    .sticky-top-section {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .sticky-bottom-section {
      position: sticky;
      bottom: 0;
      z-index: 1;
    }

    .options-button-container {
      position: relative;
      z-index: 2;
    }

    ul {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      li {
        a {
          transition: all 0.5s ease;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          .trash-btn {
            opacity: 0;
            transition: all 0.5s ease-in;
            color: #ec8c95;
            &:hover {
              color: #dc3545;
            }
          }

          &:hover {
            background-color: #e9ede9;
            cursor: pointer;
            transform: scale(1.01);

            .trash-btn {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .searching-animation-item {
    height: 18px;
    margin-bottom: 10px;
  }

  .drop-in {
    animation: dropIn 0.5s ease-in-out;
  }

  @keyframes dropIn {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .zoom-in {
    animation: zoomIn 0.5s ease-in-out;
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}
