.success-message-wrapper {
  img {
    transition: transform ease-in-out 0.8s;
  }
  &:hover {
    img {
      transform: scale(0.95);
    }
  }
}
