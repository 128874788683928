.reports-container-wrapper {
  Table {
    overflow-x: scroll;
  }

  th {
    text-align: center !important;
    text-transform: uppercase !important;
    font-size: 12px !important;
    padding: 5px !important;
    font-weight: 600;
  }

  .name-column {
    min-width: 20rem !important;
    text-align: start !important;
  }

  td {
    text-align: center;
    text-transform: uppercase !important;
    font-size: 14px !important;
    color: rgb(34, 34, 34);
  }

  td.opening-balance-style {
    text-align: center;
    text-transform: uppercase !important;
    font-size: 12px !important;
    color: rgb(34, 34, 34);
    padding: 4px !important;
    font-weight: 700;
  }

  .report-container-td {
    text-align: start !important;
    vertical-align: top !important;
  }

  .total-wrapper {
    td {
      font-size: 14px !important;
      color: black !important;
      font-weight: bold;
      padding: 8px !important;
    }

    .text-field {
      text-align: center !important;
    }
  }
}

.simplebar-content {
  padding: 0px !important;
}

.table-loading-wrapper {
  padding: 0px !important;
}

.alert-styles {
  td {
    padding: 4px !important;
  }

  .alert {
    margin-top: 4px !important;
    font-size: 12px !important;
    text-transform: none;
  }
}

.report-class-style {
  font-size: 16px !important;
  text-transform: uppercase !important;
  color: black !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.report-class-style:hover {
  cursor: pointer !important;
  color: red !important;
  font-size: 17px !important;
}

.report-ledger-style {
  color: black !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.report-table-heading {
  font-size: 16px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  font-weight: 600 !important;
}

.report-total-title {
  font-weight: bold !important;
  color: black !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.report-column-custom-style {
  .sn-style {
    min-width: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.35rem !important;
    font-size: 12px !important;
  }
  td {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    // min-width: 8.5rem;
  }
}

.report-column-content-custom-style {
  font-size: 13px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.simplebar-report-style {
  height: 70vh;
  overflow-x: auto;
  overflow-y: auto;
}

.simplebar-report-no-pagination-style {
  height: 75vh; //change 66 to 75 for bank transaction report
  overflow-x: auto;
  overflow-y: auto;
}

.simplebar-report-for-ledger-pagination-style {
  height: 85vh;
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(251, 251, 251) !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 10px !important;
    display: inline;
    unicode-bidi: bidi-override;
    margin-left: 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgb(237, 243, 248);
  }

  &::-webkit-scrollbar-thumb {
    background: #e0e9f6 !important;
    border-radius: 10px !important;
    margin-left: 20px !important;
  }
}

.simplebar-report-for-ledger-report-pagination-style {
  height: 87vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.report-ledger-hover-style:hover {
  color: blue !important;
  cursor: pointer;
  font-size: 15px !important;
}

.custom-title-reports {
  display: inline-block;
  text-decoration: none;
}

.report-list-custom-style {
  list-style: none;

  .custom-title-reports::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: linear-gradient(to left, #a7afb4 40%, #e2ebf0);
    transition: width 0.4s;
  }

  .custom-title-reports:hover::after {
    width: 100%;
  }
}

.underline-report-header {
  width: 200px;
  height: 8px;
  border-bottom: 1px dashed #6f7275;
  color: #6f7275;
}

.auto-scroll-report {
  .top-bottom {
    transition: transform 0.3s ease-in-out;
    animation: moveUpDown 1s infinite;
    right: 10px;
    bottom: 20px;
    box-shadow: none !important;
    z-index: 999;
  }

  .bottom-top {
    transition: transform 0.3s ease-in-out;
    animation: moveUpDown 1s infinite;
    box-shadow: none !important;
    z-index: 999;
  }
}

.simple-bar-report-main-container {
  height: 84vh;
}

.report-table-simple-scroll-bar-color-style {
  .simplebar-track {
    .simplebar-scrollbar::before {
      background-color: #96aec1 !important;
    }
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}
