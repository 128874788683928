.loading-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1020;
  align-items: center;
  justify-content: center;
  display: flex;
  .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loading-overlay-for-search {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1020;
  align-items: center;
  justify-content: center;
  display: flex;

  .search-icon {
    width: 30px;
    height: 30px;
    display: flex;
    color: whitesmoke;
    animation: search-circle 7s linear infinite;
  }

  @keyframes search-circle {
    0% {
      transform: translate(0, -50px);
    }
    6.25% {
      transform: translate(5px, -40px);
    }
    12.5% {
      transform: translate(10px, -10px);
    }
    18.75% {
      transform: translate(40px, -5px);
    }
    25% {
      transform: translate(50px, 0);
    }
    31.25% {
      transform: translate(40px, 5px);
    }
    37.5% {
      transform: translate(10px, 10px);
    }
    43.75% {
      transform: translate(5px, 40px);
    }
    50% {
      transform: translate(0, 50px);
    }
    56.25% {
      transform: translate(-5px, 40px);
    }
    62.5% {
      transform: translate(-10px, 10px);
    }
    68.75% {
      transform: translate(-40px, 5px);
    }
    75% {
      transform: translate(-50px, 0);
    }
    81.25% {
      transform: translate(-40px, -5px);
    }
    87.5% {
      transform: translate(-10px, -10px);
    }
    93.75% {
      transform: translate(-5px, -40px);
    }
    100% {
      transform: translate(0, -50px);
    }
  }
}
