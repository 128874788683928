.multi-tenancy-login {
    // .form-container {
    //     height: 260px;
    // }

    .card-body {
        display: flex;
        align-items: center;
    }

    h1 {
        font-size: 15px !important;
        font-weight: 600 !important;
    }
}

.mutli-tenant-list {
    .login-button {
        button {
            font-size: 10px;
            border: none;
            color: #ffffff;
            padding: 2px 10px;
            background-color: #00D27A;
        }
    }
}