.system-permission-view-card {
  .with-footer-content-height {
    height: 100%;
    overflow: auto;
    max-height: 76.7vh;
  }
  .without-footer-content-height {
    height: 100%;
    overflow: auto;
    max-height: 85.5vh;
  }
}

.system-admin-permission-tree-view {
  height: 100%;
  overflow: auto;
  min-width: 350px;
  .treeview-list {
    padding-left: 30px !important;
  }

  .tree-item-with-custome-icon {
    padding-left: 0.5rem !important;
    &::before {
      display: none !important;
    }
  }

  .tree-item-has-no-childrens {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    a.collapse-toggle {
      &::after {
        display: none !important;
      }
    }
    .treeview-text {
      margin-left: 0 !important;
      display: flex !important;
      align-items: center !important;
      font-weight: 600 !important;
      font-size: 0.8333333333rem !important;
      color: var(--falcon-600) !important;
      padding: 0.25rem 0 !important;
      margin-bottom: 0 !important;
    }

    input {
      margin: 0 0.5rem 0.25rem 0 !important;
      position: relative !important;
      left: 0 !important;
      position: unset !important;
    }
  }
}
