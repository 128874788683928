.customer-documents-style {
  Table {
    overflow-x: scroll;
  }

  th {
    text-align: center !important;
    text-transform: uppercase !important;
    font-size: 12px !important;
    padding: 5px !important;
    font-weight: 600;
  }

  .name-column {
    min-width: 20rem !important;
    text-align: start !important;
  }

  td {
    font-size: 14px !important;
    color: rgb(34, 34, 34);
  }

  .report-container-td {
    text-align: start !important;
    vertical-align: top !important;
  }

  .total-wrapper {
    td {
      font-size: 14px !important;
      color: black !important;
      font-weight: bold;
      padding: 8px !important;
    }

    .text-field {
      text-align: center !important;
    }
  }
}
