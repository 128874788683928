.app-date-time-picker-wrapper {
  .react-datepicker {
    border-radius: 0px !important;
  }

  .react-datepicker .react-datepicker-time__header {
    padding-bottom: 7px !important;
    font-size: 13px !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    color: black !important;
    font-size: 11px !important;
  }

  .react-datepicker .react-datepicker__day--today {
    background-color: rgb(212, 229, 251) !important;
    border-radius: 0px !important;
  }

  .react-datepicker .react-datepicker__day {
    color: black !important;
    font-size: 11px !important;
    border-radius: 0px !important;
  }

  .react-datepicker .react-datepicker__day--outside-month {
    color: rgb(183, 192, 210) !important;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    left: 280px !important;
    padding-bottom: 5px !important;
  }

  .react-datepicker__close-icon::after {
    background-color: transparent !important;
    color: rgb(183, 192, 210) !important;
    font-size: 17px;
  }

  .react-datepicker-popper {
    padding-top: 2px !important;
    border-radius: 0px !important;
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker .react-datepicker__header {
    border-radius: 0px !important;
    font-size: 12px;
    padding-bottom: 0px !important;
    background-color: whitesmoke !important;

    select {
      border: 1px solid rgb(158, 155, 155);
      width: 7rem;
    }
  }

  .react-datepicker .react-datepicker__month {
    border-radius: 0px !important;
  }

  .react-datepicker .react-datepicker__current-month {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .react-date-picker__inputGroup__input {
    height: 50% !important;
  }


  .custome-date-picker {
    width: 100%;
    color: black !important;

    .react-date-picker__wrapper {
      border: 1px solid #b5b5b5;
      font-size: 12px;
      font-weight: 400 !important;
      padding: 0.3125rem 1rem;
      background-color: #ffffff !important;
    }

    input {
      background-color: transparent !important;
    }

    .react-daterange-picker__range-divider {
      flex: 1 1 auto !important;
    }
  }

  .custome-date-picker.is-invalid {
    .react-date-picker__wrapper {
      border-color: #dc3545 !important;
    }
  }

  .custome-date-picker-calendar {
    width: 235px;
    box-shadow: 1px 2px 5px #c9c9c9;
    font-size: 0.7rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;

    .react-calendar__tile {
      line-height: 10px;
      font-weight: 500;
      font-size: 0.65rem;
    }

    .react-calendar__tile--now {
      background-color: #055ac1 !important;
      color: #fff;
      border-radius: 4px !important;
      padding: 5px 3px;

      &:hover {
        background-color: #055ac1 !important;
        color: #fff;
      }
    }

    .react-calendar__navigation {
      button {
        min-width: 27px;
      }
    }
  }
}