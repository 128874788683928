.side-bar-styles {
  height: 85vh !important;
  .form-label {
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
  .css-13cymwt-control {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    font-size: 12px !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    min-height: 32px !important;
    height: 5px !important;
    border: 1px solid #b5b5b5 !important;
  }
  .css-t3ipsp-control {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    font-size: 12px !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    min-height: 32px !important;
    height: 5px !important;
    border: 1px solid #b5b5b5 !important;
  }
  .css-1hb7zxy-IndicatorsContainer {
    padding: 3px !important;
  }
  .void-simplebar-style {
    height: 55vh;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}

.void-simplebar-view-page-style-with-accordion {
  height: 61.2vh;
  margin-top: 7px;
}
.void-simplebar-view-page-style-without-accordion {
  height: 84.5vh;
  margin-top: 7px;
}

.delete-modal {
  .modal-content {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
  }
}
