.image-carousel {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    .image-preview {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 1200px;
        height: 700px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 996;
    }
    .left-button {
        left: 44%;
        z-index: 997;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        color: white;
        box-shadow: none;
        position: fixed;
        top: 80%;
        border-radius: 50% !important;
        transform: translateY(-50%);
        background-color: #292848db;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .right-button {
        box-shadow: none;
        right: 44%;
        position: fixed;
        border-radius: 50% !important;
        height: 40px;
        width: 40px;
        color: white;
        top: 80%;
        border-radius: 50%;
        transform: translateY(-50%);
        background-color: #292848db;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .close-button {
        right: 48.2%;
        z-index: 997;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        color: white;
        box-shadow: none;
        position: fixed;
        top: 80%;
        border-radius: 50% !important;
        transform: translateY(-50%);
        background-color: #292848db;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}