.whatsapp-modal {
  .modal-header {
    border-radius: 0px !important;
    .modal-title {
      div {
        span {
          color: black !important;
          font-size: 16px !important;
          margin-left: 5px;
          font-weight: 540;
        }
      }
    }
  }
  .whatsapp-modal-body {
    .form-control {
      height: 36px !important;
    }
  }
  .whatsapp-modal-footer {
    border-radius: 0px !important;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .qr-code-modal-body {
    background-color: white;
  }
}
