.system-settings-table {
  .form-check-input:focus {
    box-shadow: none !important;
  }
}
.settings-page-card-wrapper {
  max-height: 46vh;
  min-height: 23vh;



  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;
    font-weight: 500 !important;
  }

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }

  .finance-settings-label {
    .form-label {
      font-weight: 400 !important;
      padding: 6.7px !important;
      font-size: 14px !important;
      color: rgb(66, 66, 66) !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
  }
}

.settings-page-small-card-wrapper {
  max-height: 46vh;
  min-height: 16vh;

  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;
    font-weight: 500 !important;
  }

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }

  .finance-settings-label {
    .form-label {
      font-weight: 400 !important;
      padding: 6.7px !important;
      font-size: 11px !important;
      color: rgb(66, 66, 66) !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
  }
}

.settings-page-medium-card-wrapper {
  max-height: 46vh;
  min-height: 20vh;

  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;
    font-weight: 500 !important;
  }

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }

  .finance-settings-label {
    .form-label {
      font-size: 14px !important;
      color: rgb(66, 66, 66) !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
  }
}

.sales-settings-page {
  min-height: 30vh;

  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;

    font-weight: 500 !important;
  }

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.purchase-settings-page {
  min-height: 30vh;

  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;

    font-weight: 500 !important;
  }

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.inventory-settings-page {
  min-height: 25vh;

  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;
    font-weight: 500 !important;
  }

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.system-settings-navbar {
  .system-settings-navbar-item {
    color: rgb(41, 40, 40);
    font-size: 14px !important;
  }
}

.settings-wrapper {
  .dummy-image {
    height: 11rem;
    width: 10.5rem;
    border: 1px dashed black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 10px !important;
      margin: 0px !important;
    }
  }

  .company-settings-tab-style {
    overflow-x: none;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;

    .nav-tabs {
      border-bottom: 1px solid rgb(241, 239, 239) !important;
    }

    .nav-tabs .nav-item .nav-link {
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
      font-size: 12px !important;
      padding: 7px !important;
    }
  }

  .input-group-text {
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 10px !important;
  }
}

.company-settings {
  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;
    font-weight: 500 !important;
  }
}

.company-settings-label {
  font-size: 14px !important;
  color: rgb(66, 66, 66) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px !important;
  font-weight: 500 !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.settings-button-styles {
  padding: 5px 8px !important;
  color: white !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.settings-badge-style {
  margin-left: 5px !important;
  font-size: 11px !important;
  text-transform: uppercase;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.company-settings-branch-style {
  font-size: 16px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.company-select-width-styles {
  .css-b62m3t-container {
    width: 66.5% !important;
  }
}

.opening-balace-settings-page {
  .settings-card-header {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 16px !important;
    color: rgb(66, 66, 66) !important;
  }

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.print-settings-page-style {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;

  .form-label {
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }

  .header-image {
    .figure {
      text-align: center !important;

      .profile-image {
        text-align: center !important;
      }
    }
  }
}

.print-settings-tab-style {
  .custom-empl-header-tabs>.nav-item>.nav-link {
    color: #1d1d1d !important;
  }
  .print-setting-sub-heading>.nav-item>.nav-link{
    font-size: 12px;
    font-weight: 500;

  }

  .custom-empl-header-tabs>.nav-item>.nav-link.active {
    background-color: #626e89 !important;
    color: rgb(255, 255, 255) !important;
  }

  .form-control {
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;

    font-weight: 500 !important;
  }

  .ql-container {
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    font-weight: 500 !important;
  }

  .input-group-text {
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    color: rgb(66, 66, 66) !important;
    font-weight: 500 !important;
  }
}

.with-vertical-line {
  border-right: 1px solid rgb(104, 104, 104);
  margin: 0px !important;
  padding: 0px !important;
}