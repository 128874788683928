.switch-controller {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: #22c35d;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #22c35d;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(18px);
      -ms-transform: translateX(18px);
      transform: translateX(18px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 10px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &::before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 5px;
      bottom: 3.5px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
