.toggle-main-layout {
    label {
        position: relative;
        display: block;
        width: 45px;
        height: 18px;
        background: rgb(251, 248, 248);
        border-radius: 200px;
        box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.4),
            inset 0 -3px 20px rgba(255, 255, 255, 0.4);
        cursor: pointer;
        transition: all .3s ease-in-out;
    }

    label::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: 1px;
        left: 2px;
        background: linear-gradient(180deg, #ffcc96, #d8865d);
        border-radius: 180px;
        // box-shadow: -3px -3px 10px #00000040, 3px 3px 20px #00000040;
        transition: all .3s ease-in-out;
    }

    input {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    input:checked+label {
        background: #242424;
    }

    input:checked+label::after {
        left: 43px;
        transform: translateX(-100%);
        background: linear-gradient(180deg, #777, #3a3a3a);
    }

    .moon-icon {
        position: absolute;
        top: 4px;
        color: #fff;
        right: 4px;
        z-index: 999;
    }

    .sun-icon {
        position: absolute;
        top: 2px;
        color: #ffd900;
        left: 3px;
        z-index: 999;
    }

    label:active::after {
        width: 20px;
    }

    input:checked+label+.background {
        background: #242424;
    }
}