.fiexed-assets-item-info {
    .app-swiper {
        img {
            height: 280px;
            width: 100%;
        }
    }
    h1 {
        font-size: 13x !important;
        font-weight: 800 !important;
    }
    .heading {
        font-size: 12px;
        color: black;
        font-weight: 800;
    }
    .value {
        color: black;
    }

    .swiper-bottom-image {
        margin-top: 10px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100px;
    }
}