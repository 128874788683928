$lineLightColor: #213e560e;

.app-activity-log-container {
  .app-activity-log-items-cover {
    .app-activity-log-item {
      transform-origin: top left;
      animation-name: animateTableContentView;
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;

      &:first-child {
        .app-activity-log-item-icon-area {
          .app-activity-log-item-line-span {
            display: none;
          }
        }
      }

      &:last-child {
        .app-activity-log-item-icon-area {
          &::after {
            display: none;
          }
        }
      }

      &:hover {
        .app-activity-log-item-content-area-cover {
          transition: all 0.2s ease-in;
          transform: scale(1.01);
          opacity: 1;
        }
      }

      .app-activity-log-item-content-area-cover {
        opacity: 0.85;
      }

      .app-activity-log-item-icon-area {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 70%;
          height: 2px;
          background-color: $lineLightColor;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }

        &::after {
          content: '';
          position: absolute;
          width: 2px;
          height: 50%;
          background-color: $lineLightColor;
          left: 30%;
          transform: translateX(-50%);
          bottom: 0;
        }

        .app-activity-log-item-line-span {
          position: absolute;
          width: 2px;
          height: 50%;
          background-color: $lineLightColor;
          left: 30%;
          transform: translateX(-50%);
          top: 0;
        }

        .avatar {
          position: relative;
          z-index: 1;
        }
      }

      .app-activity-log-item-content-area {
        p {
          font-size: 0.73rem;
        }
      }
    }
  }
}
