.simplebar-overview-page-style {
  height: 67vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.basic-details-wrapper {
  .basic-details-label {
    text-align: start;
    padding-left: 30px !important;
    h6 {
      font-size: 12px !important;
      text-transform: uppercase;
    }
  }
  .basic-details {
    text-align: start;
    h6 {
      font-size: 14px !important;
    }
  }
}
.customer-image {
  cursor: pointer !important;
  .figure {
    display: flex;
    align-items: center;
    justify-content: center;
    .profile-image {
      width: 105px !important;
      height: 105px !important;
      border-radius: 50% !important;
      -webkit-border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      -ms-border-radius: 50% !important;
      -o-border-radius: 50% !important;
      border: 1px solid black;
      .profile-image-gif {
        width: 105px !important;
        height: 105px !important;
        border-radius: 50% !important;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        -ms-border-radius: 50% !important;
        -o-border-radius: 50% !important;
      }
    }
  }
}
.image-upload-section {
  height: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  cursor: pointer !important;
  p {
    font-size: 11px !important;
  }
}
.customer-image-save-button {
  box-shadow: none !important;
}
