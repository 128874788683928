.app-launchpad-cover {
  background-color: #2222249c;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 15px;
  overflow: hidden;
  display: none;

  .app-launchpad-section-padding {
    padding: 15px;
  }

  .app-launchpad {
    position: relative;
    width: 100%;
    max-width: 700px;
    border-radius: 7px;
    height: 100%;
    min-height: 200px;
    max-height: 500px;
    background-color: #2a2c30;
    overflow: hidden;
    scale: 0;
    transition: scale 0.2s ease-in-out;

    .launchpad-searcharea-cover {
      position: sticky;
      top: 0;
      background-color: #2a2c30;
      z-index: 1;
      .search-area {
        background-color: #222224;
        border: 1px solid #393b3f;
        overflow: hidden;
        border-radius: 7px;

        .icon-cover {
          padding: 5px 10px;
          svg {
            color: #fff;
          }
        }

        .search-cover {
          width: 100%;
          overflow: hidden;
          position: relative;
          span {
            color: #737374;
            position: absolute;
            top: 6px;
            left: 5px;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          input[type='search'] {
            position: relative;
            z-index: 1;
            background-color: transparent;
            border: none;
            outline: none;
            width: 100%;
            color: #fff;
            padding: 6px 5px;
          }
        }
      }
    }

    .app-launchpad-content-area {
      position: relative;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      overflow-y: overlay;
      .moving-animation-rea {
        flex-shrink: 0;
        width: 100%;
        transition: all 0.3s ease-in-out;
      }
      .move-item-left {
        transform: translateX(-100%);
        opacity: 0;
      }
      .move-item-left-with-opacity {
        transform: translateX(-100%);
        opacity: 1;
      }
      .move-item-right {
        transform: translateX(100%);
        opacity: 0;
      }
      .move-item-right-with-opacity {
        transform: translateX(100%);
        opacity: 1;
      }
      .move-item-center {
        transform: translateX(0);
        opacity: 1;
      }
      .app-launchpad-section-title {
        color: #fff;
      }

      .app-launchpad-module-grid-cover {
        margin: 0;
        .col {
          padding: 0;
          .app-launchpad-module-grid-item {
            text-align: center;
            transition: background 0.2s ease-in-out;
            padding: 15px;
            border-radius: 7px;

            svg {
              width: 28px;
              height: 28px;
            }

            p {
              color: #fff;
              margin-top: 7px;
              margin-bottom: 0;
              font-weight: 200;
              font-size: 0.71rem;
            }

            svg,
            p {
              transition: transform 0.2s ease-in-out;
            }

            &:hover {
              background-color: #35373a;
              svg,
              p {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      .app-launchpad-quick-link-cover {
        margin: 0;
        .col {
          padding: 0;
          .app-launchpad-quick-link-item {
            transition: background 0.2s ease-in-out;
            padding: 12px;
            border-radius: 7px;
            svg {
              margin-right: 7px;
            }
            p {
              margin: 0;
              font-size: 0.71rem;
            }

            svg,
            p {
              color: #fff;
            }

            &:hover {
              background-color: #35373a;
            }
          }
        }
      }
    }

    .app-launchpad-footer {
      margin-top: auto;
      width: 100%;
      background-color: #2a2c30;
      border-top: 1px solid #393b3f;
      .app-launchpad-control-item {
        span {
          font-size: 0.7rem;
        }
        .app-launchpad-control-key {
          background-color: #222224;
          padding: 3px 4px;
          border-radius: 3px;
          font-size: 0.5rem;
        }
      }
    }
  }

  .orbit-animation {
    position: relative;
    -webkit-animation: myOrbit 3s linear infinite;
    -moz-animation: myOrbit 3s linear infinite;
    -o-animation: myOrbit 3s linear infinite;
    animation: myOrbit 3s linear infinite;
  }

  .orbit-animation-text {
    margin-top: 30px;
    word-break: break-all;
  }
}

.show-launchpad {
  display: flex;
  .app-launchpad-scale {
    scale: 1;
  }
}

@-webkit-keyframes myOrbit {
  from {
    -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}

@-moz-keyframes myOrbit {
  from {
    -moz-transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}

@-o-keyframes myOrbit {
  from {
    -o-transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}

@keyframes myOrbit {
  from {
    transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}
