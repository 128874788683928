.ai-chatbot-cover {
  .small-message {
    font-size: 0.72rem;
  }
  .chatbot-header {
    background-color: #213e56;
    border-bottom-color: #213e56 !important;
    .offcanvas-title {
      color: #fff;
      span {
        margin-top: 3px;
      }
    }
    button {
      color: #fff;
      filter: invert(100%);
    }
  }
  .content-area {
    background-color: #1e2a41;

    .chat-response-area {
      height: 90%;
      overflow-x: hidden;
      overflow-y: auto;
      .chat-welcome-area {
        .chat-icon {
          background-color: #fff;
          border-radius: 50%;
          padding: 10px;
          svg {
            color: #1e2a41;
          }
        }
      }
      .title {
        margin-top: 12px;
        font-size: 1.3rem;
        color: #fff;
      }
    }

    .chat-initial-helpers {
      a {
        text-decoration: none;
      }
      .helper-item {
        transition: all 0.3s ease;
        color: #fff;
        background-color: #1e2a41;
        border: 1px solid #3a465f;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 15px;
        p {
          margin: 0;
          margin-left: 12px;
          font-size: 0.8rem;
        }
        &:hover {
          border-color: #7b8395;
        }

        .form-item-group {
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }

          p {
            margin-left: 0;
          }

          .form-input {
            background-color: #1e2a41;
            .chatbot-input-item__control {
              background-color: #1e2a41;
              .chatbot-input-item__single-value {
                color: #fff;
              }
            }
            .react-date-picker__inputGroup {
              span,
              input {
                color: #fff;
              }
            }
          }
          .form-data {
            opacity: 0.7;
            font-size: 0.7rem;
          }
        }
      }
    }

    .chat-items {
      .chat-item {
        color: #fff;
        border: 1px solid #3a465f;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 25px;
        p {
          margin: 0;
          margin-left: 12px;
          font-size: 0.8rem;
        }
        .data-para {
          margin-top: 15px;
          font-size: 0.9rem;
          font-weight: bold;
        }
        .spinners {
          div {
            width: 10px;
            height: 10px;
          }
        }
      }
      .question {
        background-color: #213e56;
      }
      .answer {
        background-color: #425a5f;
      }
    }
  }
  .chatbot-footer {
    overflow: hidden;
    background-color: #213e56;
    textarea {
      resize: none;
      font-size: 0.9rem !important;
      background-color: #213e56;
      color: #fff;
    }

    button {
      transition: all 0.5s ease;
      padding: 5px 8px;
      &:hover {
        background-color: #7b8395;
        color: #fff;
      }
    }

    .chatbot-form-submit-button {
      svg {
        transform: rotate(50deg);
      }
    }
  }
}
