@media only screen and (min-width: 1200px) and (max-width: 1540px) {
    .navbar-vertical.navbar-expand-xl {
        width: 10.2rem !important;

        img {
            width: 5.9rem !important;
        }

        .navbar-collapse {
            font-size: 12px !important;
            width: 10.2rem !important;
        }

        .navbar-toggler-humburger-icon {
            height: 0.4rem !important;
            width: 1.8rem !important;
        }

        .nav-link-text {
            margin-left: 0px !important;
        }

        .nav-link-icon {
            margin-left: 3px !important;
        }

        .hover-style:hover {
            font-size: 12px !important;
        }

        .navbar-vertical-content {
            width: 10.2rem !important;

            .navbar-nav {
                font-size: 11px !important;


                .nav-item {
                    padding-top: 5px;
                }

                .nav-link-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 0.5rem !important;
                    height: 0.5rem !important;
                }
            }
        }

        +.content {
            margin-left: 10.8rem !important;

            .navbar-top {
                height: 3.8rem !important;
                margin-left: 0px !important
            }
        }




    }

    .navbar-vertical-collapsed {
        .navbar-vertical.navbar-expand-xl {
            width: 3.125rem !important;

            +.content {
                margin-left: 3.7rem !important;

                .navbar-top {
                    height: 3.8rem !important;
                    margin-left: 0px !important;
                    padding-left: 7.5rem !important;
                }
            }

            .navbar-vertical-content {
                .navbar-nav {
                    font-size: 11px !important;

                    .nav-item {
                        padding-top: 20px;

                        .active-style-with-children {
                            height: 20px !important;
                            display: flex;
                            padding-top: 0px;
                        }
                    }

                    .nav-link-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 0.5rem !important;
                        height: 0.5rem !important;
                        padding: 10px;
                    }
                }
            }
        }

    }

    .react-grid-item {
        height: 140px;

        .fs--2 {
            font-size: 0.6rem !important;
        }

        h6 {
            font-size: 12px !important;
        }

        .gros-profit-summary {
            h6 {
                font-size: 13px !important;
            }

            .w-50 {
                width: 60% !important;
            }

            .echarts-for-react {
                width: 5rem !important;
                height: 6rem !important;
            }
        }

        .cheque-summary {
            .badge {
                width: 68px !important;
                height: 41px;
            }

            .count {
                font-size: 12px !important;
            }

            .name {
                font-size: 8px !important;
            }
        }

        .weekly-sales {
            h2 {
                font-size: 13px !important;
            }

            .content-weekly {
                gap: 4px !important;
            }
        }

        .weather {
            img {
                width: 45px;
                height: 45px;
            }

            .current-temp {
                font-size: 23px !important;
            }

            .perceiption {
                font-size: 15px !important;
            }
        }

        .contract-details {

            .echarts-for-react {
                position: absolute !important;
                width: 201px !important;
                height: 275px !important;
                left: -2px !important;
                font-size: 11px;
            }
        }

        .total-receivables,
        .total-payable {
            .tile-heading {
                .col {
                    padding: 5px !important;
                }
            }
        }

        .best-selling-product {
            th {
                font-size: 12px !important;
            }

            .percentage-value {
                font-size: 15px !important;
            }
        }
    }

    .finance-dashboard-payable {
        .card {
            height: 160px !important;
        }

        .total-receivables,
        .total-payables {
            .tile-heading {
                .col {
                    padding: 5px !important;
                }

                h6 {
                    font-size: 13px !important;
                }

                .col-auto {
                    padding: 0px;
                }
            }
        }
    }
}