.react-date-picker__inputGroup__input {
  height: 50% !important;
}

.custome-date-picker {
  width: 100%;
  color: black !important;

  .react-date-picker__wrapper {
    border: 1px solid #b5b5b5;
    font-size: 12px;
    font-weight: 400 !important;
    padding: 0.3125rem 1rem;
    background-color: #ffffff !important;
  }

  input {
    background-color: transparent !important;
  }

  .react-daterange-picker__range-divider {
    flex: 1 1 auto !important;
  }
}

.custome-date-picker.is-invalid {
  .react-date-picker__wrapper {
    border-color: #dc3545 !important;
  }
}

.custome-date-picker-calendar {
  width: 235px;
  box-shadow: 1px 2px 5px #c9c9c9;
  font-size: 0.7rem;
  // border-bottom-left-radius: 5px;
  // border-bottom-right-radius: 5px;
  border: none;
  position: fixed !important;

  .react-calendar__tile {
    line-height: 10px;
    font-weight: 500;
    font-size: 0.65rem;
  }

  .react-calendar__tile--now {
    background-color: #055ac1 !important;
    color: #fff;
    border-radius: 4px !important;
    padding: 5px 3px;

    &:hover {
      background-color: #055ac1 !important;
      color: #fff;
    }
  }

  .react-calendar__navigation {
    button {
      min-width: 27px;
    }
  }
}
