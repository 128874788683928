.common-serach {
  position: relative;
  margin-right: 10px;
  .search-input {
    width: 15rem;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    padding-left: 25px;
  }
  .search-box-icon {
    top: 8px;
    left: 8px;
  }
  .search-box-close-btn-container {
    position: relative;
    bottom: 27px;
    left: 212px;
  }
}

@media screen and (max-width: 1024px) {
  .common-serach {
    display: none !important;
  }
}
