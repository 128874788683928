.pdf-viewer-modal {
  width: 100% !important;
  height: calc(100% - 3.5rem);
  max-width: 90vw !important;
  .modal-title {
    font-size: 1.4rem !important;
    color: #000;
  }

  .modal-content {
    height: auto;
    min-height: calc(100% - 3.5rem);
    border-radius: 0;
  }
}

.pdf-tab-view-simplebar-style {
  .simplebar-content-wrapper {
    display: flex;
    .simplebar-content {
      width: 100%;
      display: flex;
    }
  }
}

.pdf-viewer-tab {
  .title {
    font-size: 1.4rem !important;
    color: #000;
  }
}
