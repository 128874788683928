.qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  //   background-color: #f8f8f8;
}

.qr-code {
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-gap: 2px;
  width: 250px;
  height: 250px;
}

.qr-cell {
  width: 5px;
  height: 5px;
  background-color: rgb(151, 151, 151);
  opacity: 0;
  animation: buildQR 1s ease-in-out infinite;
}

.qr-cell:nth-child(odd) {
  animation-delay: calc(0.1s * var(--i));
}

.qr-cell:nth-child(even) {
  animation-delay: calc(0.2s * var(--i));
}

@keyframes buildQR {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
